import React from 'react';
import PageHeaderContent from '../../components/PageHeader';
import { BsInfoCircleFill } from 'react-icons/bs';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { data } from './utils';
import 'react-vertical-timeline-component/style.min.css';
import './styles.scss';
import { MdWork } from 'react-icons/md';
const Resume = () => {
    return (
        <section id='resume' className='resume'>

            <PageHeaderContent
                headerText="My Resume"
                icon={<BsInfoCircleFill size={40} />}
            />
            <div className="timeline">
            <div className="timeline__education">
                    <h3 className='timeline__education__header-text'>
                        Education
                    </h3>
                    <VerticalTimeline
                        layout='1-column'
                        lineColor='var(--yellow-theme-main-color)'
                    >
                        {
                            data.education.map((items, i) => (
                                <VerticalTimelineElement
                                    key={i}
                                    className='timeline__education__vertical-timeline-element'
                                    contentStyle={{
                                        background: 'none',
                                        color: 'var(--yellow-theme-sub-text-color)',
                                        border: '1.5px solid var(--yellow-theme-main-color)'
                                    }}
                                    date='2022 - Present'
                                    icon={<MdWork />}
                                    iconStyle={{
                                        background: '#181818',
                                        color: 'var(--yellow-theme-main-color)',
                                    }}
                                >
                                    <div className="vertical-timeline-element-title-wrapper">
                                        <h3 className='vertical-timeline-element-title'>
                                            {items.title}
                                        </h3>

                                        <h4 className='vertical-timeline-element-subtitle'>
                                            {items.subTitle}
                                        </h4>
                                    </div>
                                    <p className='vertical-timeline-element-title-wrapper-description'>{items.description}</p>

                                </VerticalTimelineElement>
                            ))
                        }
                    </VerticalTimeline>
                </div>
                <div className="timeline__experience">
                    <h3 className='timeline__experience__header-text'>
                        Experience
                    </h3>
                    <VerticalTimeline
                        layout='1-column'
                        lineColor='var(--yellow-theme-main-color)'
                    >
                        {
                            data.experience.map((items, i) => (
                                <VerticalTimelineElement
                                    key={i}
                                    className='timeline__experience__vertical-timeline-element'
                                    contentStyle={{
                                        background: 'none',
                                        color: 'var(--yellow-theme-sub-text-color)',
                                        border: '1.5px solid var(--yellow-theme-main-color)'
                                    }}
                                    date='2024 - Present'
                                    icon={<MdWork />}
                                    iconStyle={{
                                        background: '#181818',
                                        color: 'var(--yellow-theme-main-color)',
                                    }}
                                >
                                    <div className="vertical-timeline-element-title-wrapper">
                                        <h3 className='vertical-timeline-element-title'>
                                            {items.title}
                                        </h3>

                                        <h4 className='vertical-timeline-element-subtitle'>
                                            {items.subTitle}
                                        </h4>

                                    </div>
                                    <p className='vertical-timeline-element-title-wrapper-description'>{items.description}</p>

                                </VerticalTimelineElement>
                            ))
                        }

                    </VerticalTimeline>
                </div>
                <div className="timeline__education">
                    <h3 className='timeline__education__header-text'>
                        Projects
                    </h3>
                    <VerticalTimeline
                        layout='1-column'
                        lineColor='var(--yellow-theme-main-color)'
                    >
                        {
                            data.projects.map((items, i) => (
                                <VerticalTimelineElement
                                    key={i}
                                    className='timeline__education__vertical-timeline-element'
                                    contentStyle={{
                                        background: 'none',
                                        color: 'var(--yellow-theme-sub-text-color)',
                                        border: '1.5px solid var(--yellow-theme-main-color)'
                                    }}
                                    date={items.date}
                                    icon={<MdWork />}
                                    iconStyle={{
                                        background: '#181818',
                                        color: 'var(--yellow-theme-main-color)',
                                    }}
                                >
                                    <div className="vertical-timeline-element-title-wrapper">
                                        <h3 className='vertical-timeline-element-title'>
                                            {items.title}
                                        </h3>

                                        <h4 className='vertical-timeline-element-subtitle'>
                                            {items.subTitle}
                                        </h4>
                                    </div>
                                    <p className='vertical-timeline-element-title-wrapper-description'>{items.description}</p>
                                </VerticalTimelineElement>
                            ))
                        }
                    </VerticalTimeline>
                </div>



                <div className="timeline__education">
                    <h3 className='timeline__education__header-text'>
                        Achievements
                    </h3>
                    <VerticalTimeline
                        layout='1-column'
                        lineColor='var(--yellow-theme-main-color)'
                    >
                        {
                            data.achievements.map((items, i) => (
                                <VerticalTimelineElement
                                    key={i}
                                    className='timeline__education__vertical-timeline-element'
                                    contentStyle={{
                                        background: 'none',
                                        color: 'var(--yellow-theme-sub-text-color)',
                                        border: '1.5px solid var(--yellow-theme-main-color)'
                                    }}
                                    icon={<MdWork />}
                                    iconStyle={{
                                        background: '#181818',
                                        color: 'var(--yellow-theme-main-color)',
                                    }}
                                >
                                    <div className="vertical-timeline-element-title-wrapper">
                                        <h3 className='vertical-timeline-element-title'>
                                            {items.title}
                                        </h3>

                                        <h4 className='vertical-timeline-element-subtitle'>
                                            {items.subTitle}
                                        </h4>
                                    </div>
                                    <p className='vertical-timeline-element-title-wrapper-description'>{items.description}</p>

                                </VerticalTimelineElement>
                            ))
                        }
                    </VerticalTimeline>
                </div>


            </div>



        </section>
    )
}

export default Resume;
